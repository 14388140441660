<template>
  <v-card class="library-setting">
    <h2 class="px-6 pt-5">
      Setting
    </h2>
    <span class="d-block text-subtitle-2 px-6 mb-5">
      Halaman untuk menambahkan data pada kategori, subkategori, subsubkategori, penulis, penerbit
    </span>
    <v-tabs
      v-model="tab"
      show-arrows
      fixed-tabs
    >
      <v-tab
        v-for="tabItem,index in tabs"
        :key="tabItem.icon"
        class="elevation-0"
        @click="reRenderComponent(index)"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tabItem.icon }}
        </v-icon>
        <span>{{ tabItem.title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <library-setting-category :key="componentKey"></library-setting-category>
      </v-tab-item>
      <v-tab-item>
        <library-setting-writer :key="componentKey1"></library-setting-writer>
      </v-tab-item>
      <v-tab-item>
        <library-setting-publisher :key="componentKey2"></library-setting-publisher>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import LibrarySettingCategory from './settings/LibrarySettingCategory.vue'
import LibrarySettingPublisher from './settings/LibrarySettingPublisher.vue'
import LibrarySettingWriter from './settings/LibrarySettingWriter.vue'

export default {
  components: {
    LibrarySettingCategory,
    LibrarySettingWriter,
    LibrarySettingPublisher,
  },
  data() {
    return {
      tab: '',
      tabs: [{ title: 'Kategori' }, { title: 'Penulis' }, { title: 'Penerbit' }],
      componentKey: 0,
      componentKey1: 0,
      componentKey2: 0,
    }
  },
  methods: {
    reRenderComponent(index) {
      switch (index) {
        case 0:
          this.componentKey += 1
          break
        case 1:
          this.componentKey1 += 1
          break
        case 2:
          this.componentKey2 += 1
          break

        default:
          break
      }
    },
  },
}
</script>

<style>
</style>
